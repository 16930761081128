<script>
import DocumentListComponent from './components/resources/DocumentListComponent.vue';
import AutoriryComponent from './components/resources/AutoriryComponent.vue';
import NewsComponent from './components/resources/NewsComponent.vue';
import ITCGridComponent from './components/resources/ITCGridComponent.vue';
import BoxIconGridComponent from './components/resources/BoxIconGridComponent.vue';
import MultiSliderComponent from './components/resources/MultiSliderComponent.vue';
import FrecuentAccessComponent from './components/resources/FrecuentAccessComponent.vue';
import GridLinkComponent from './components/resources/GridLinkComponent.vue';
import CardGridComponent from './components/resources/CardGridComponent.vue';
import { frecuentes } from '@/constants/frecuentes.js';
import { institucionales } from '@/constants/institucionales.js';
import { consultas } from '@/constants/consultas.js';
import { otros } from '@/constants/otros.js';
import { accesos } from '@/constants/accesos.js';
import { obtenerForm } from './services/obtenerForm';

export default {
  name: 'HomeView',
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent
  },
  data() {
    return {
      site: 30,
      frecuentes: frecuentes,
      institucionales: institucionales,
      consultas: consultas,
      otros: otros,
      accesos: accesos
    }
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;

    const getInfo = async () => {
      const results = await obtenerForm();
      console.log(results);
    }
    try {
      getInfo();
    }
    catch (error) {
      console.log(error);
    }
  }
}
</script>
<!-- push -->
<template>
  <BoxIconGridComponent :columnas=4 titulo="" :data="this.accesos" />
  <ITCGridComponent :columnas="3" titulo="" :data="[
    {
      desc: null,
      icon: 'description',
      target: 'blank',
      title: 'Consulta de expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent'
    },
    {
      desc: null,
      icon: 'question_answer',
      target: 'blank',
      title: 'Consultas y solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent'
    },
    {
      desc: null,
      icon: 'pending_actions',
      target: 'blank',
      title: 'Turnos Educación',
      url: 'https://turnos.sanjuan.gob.ar/'
    },
  ]" />
  <MultiSliderComponent titulo="" :elementos="1" :mostrar-titulo="false" :mostrar-bordes="false" :ajustar="false" :data="[
    {
      desc: null,
      imagen: require('@/assets/img/ME_BannerCalEsc2024.jpg'),
      target: 'blank',
      url: 'https://archivoseducacion.sanjuan.gob.ar/educacion/archivos/CalendarioEscolar2024.pdf',
    }, 
    {
      desc: null,
      imagen: require('@/assets/img/Bn_InstructivoFormularioRelevamiento2024.jpg'),
      target: 'blank',
      url: 'https://educacion.sanjuan.edu.ar/mesj/Portals/0/Videos/InstructivoFormularioRelevamiento.html', 
    }, 
    { 
      desc: null,
      imagen: require('@/assets/img/Bn_RelevamientoAnual-2024.jpg'),
      target: 'blank',
      url: 'https://educacion.sanjuan.edu.ar/mesj/OficinasME/PlaneamientoEducativo.aspx',
    },
    {
      desc: null,
      imagen: require('@/assets/img/ME_Banner CarrerasNivSUP.jpg'),
      target: 'blank',
      url: 'https://archivoseducacion.sanjuan.gob.ar/educacion/estaticas/InstitutosDocente.html',
    },
    {
      desc: null,
      imagen: require('@/assets/img/bannernuevo.jpg'),
      target: 'blank',
      url: 'https://educacion.sanjuan.edu.ar/mesj/PlaneamientoEducativo/Avales.aspx',
    },
    {
      desc: null,
      imagen: require('@/assets/img/Bn_DirEducacionSecundTecnica.jpg'),
      target: 'blank',
      url: 'https://etpsanjuan.com.ar/',
    },
    {
      desc: null,
      imagen: require('@/assets/img/Bn_JardinesMaternales_EduPrivada.jpg'),
      target: 'blank',
      url: 'https://archivoseducacion.sanjuan.gob.ar/educacion/archivos/EduPrivada_JardinesMaternales2023.pdf',
    },
    {
      desc: null,
      imagen: require('@/assets/img/Bn_InstitutoSuperior_EduPrivada.jpg'),
      target: 'blank',
      url: 'https://archivoseducacion.sanjuan.gob.ar/educacion/archivos/EduPrivada_InstitutoHabilitados2023.pdf',
    },
    {
      desc: null,
      imagen: require('@/assets/img/Bn_InstitutoNoformal_EduPrivada.jpg'),
      target: 'blank',
      url: 'https://archivoseducacion.sanjuan.gob.ar/educacion/archivos/InstitutosPrivadaNoFormal.pdf',
    },
    
  ]" />

  <NewsComponent titulo="Noticias" />
  <BoxIconGridComponent :columnas=4 titulo="Más frecuentes" :data="this.frecuentes" />
  <BoxIconGridComponent :columnas=4 titulo="Institucionales" :data="this.institucionales" />
  <BoxIconGridComponent :columnas=4 titulo="Consultas" :data="this.consultas" />
  <BoxIconGridComponent :columnas=4 titulo="Otros Accesos" :data="this.otros" />

  <!-- Iframe de enlaces de interes -->
  <section class="container d-flex flex-column align-items-center">
    <h3 class="align-self-start">Enlaces de interés</h3>
    <iframe class="overflow-hidden" src="https://archivoseducacion.sanjuan.gob.ar/educacion/banner_enlaces.html"
      width="100%" height="200px"></iframe>
  </section>

  <AutoriryComponent :showProtocolo="false" :showLinks="true" :data="[
    {
      title: 'Silvia Fuentes',
      subtitle: 'Ministro de Educación',
      imagen: require('@/assets/img/mindeeduc.jpg'),
    },
  ]" />
</template> 