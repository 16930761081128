export const institucionales = [
  {
    desc: null,
    icon: 'calendar_month',
    target: '_blank',
    title: 'Calendario Escolar',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Gobierno/CalendarioEscolar.aspx',//https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=calendario
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'inventory_2',
    target: '_blank',
    title: 'Junta electoral 2023',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/estaticas/JuntaElectoral2023.html',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'menu_book',
    target: '_blank',
    title: 'Diseño Curricular',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Gobierno/Dise%C3%B1oCurricular.aspx?c=disenio',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'gavel',
    target: '_blank',
    title: 'Normas Legales',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/estaticas/ME_NormasLegales.html', //https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=normas',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'list_alt',
    target: '_blank',
    title: 'Formularios',
    url: 'https://educacion.sanjuan.edu.ar/mesj/Gobierno/Formularios.aspx',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'groups',
    target: '_blank',
    title: 'Patrimonio',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Gobierno/Patrimonio/DescargasdePlanillas.aspx?c=patrimonio',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'rate_review',
    target: '_blank',
    title: 'Capacitaciones SIGE',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=sige',
    color: "#e84c3c"
  },
  {
    desc: null,
    icon: 'Send',
    target: '_blank',
    title: 'Programas Nacionales',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=programasnacionales',
    color: "#e84c3c"
  }
]