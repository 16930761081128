export const frecuentes = [
  {
    desc: null,
    icon: 'newspaper',
    target: '_blank',
    title: 'Ofrecimientos Hs. y Cargos',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/LlamadosdePrensa/OfrecimientosdeHsC%C3%A1tedraCargosaCubrir.aspx',
    color: "#cd2006"
  },
  {
    desc: null,
    icon: 'launch',
    target: '_blank',
    title: 'Turnos Certificación Titulos',
    url: 'https://turnos.sanjuan.gob.ar/',
    color: "#cd2006"
  },
  {
    desc: null,
    icon: 'mail',
    target: '_blank',
    title: 'Correo Educación',
    url: 'https://webmail.sanjuan.edu.ar/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fwebmail.sanjuan.edu.ar%2fowa%2f',
    color: "#cd2006"
  },
  {
    desc: null,
    icon: 'contacts',
    target: '_blank',
    title: 'Junta de Clasificación',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=juntas',
    color: "#cd2006"
  },
]