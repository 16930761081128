export const otros = [
  {
    desc: null,
    icon: 'quiz',
    target: '_blank',
    title: 'Preguntas Frecuentes',
    url: '?uid=frame-https://archivoseducacion.sanjuan.gob.ar/educacion/consultas.html?c=preguntas',
    color: "#ffae55"
  },
  {
    desc: null,
    icon: 'call',
    target: '_blank',
    title: 'Teléfonos Educación',
    url: 'https://educacion.sanjuan.edu.ar/mesj/Ministerio/Tel%C3%A9fonosdelMinisterio.aspx',
    color: "#ffae55"
  },
  {
    desc: null,
    icon: 'map',
    target: '_blank',
    title: 'Guía de Trámites',
    url: 'https://tramite.sanjuan.gob.ar/tramite/cat/15',
    color: "#ffae55"
  },
  {
    desc: null,
    icon: 'question_answer',
    target: '_blank',
    title: 'Contáctenos',
    url: '?uid=CUMV-VIEW-FormComponent',
    color: "#ffae55"
  }
]