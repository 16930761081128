export const consultas = [
  {
    desc: null,
    icon: 'apartment',
    target: '_blank',
    title: 'Establecimientos',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/OfertaEducativa/EstablecimientosEducativos.aspx?c=establecimientos',
    color: "#ff8500"
  },
  {
    desc: null,
    icon: 'school',
    target: '_blank',
    title: 'Unidades Educativas',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/OfertaEducativa/UnidadesEducativas.aspx?c=unidades',
    color: "#ff8500"
  },
  {
    desc: null,
    icon: 'book',
    target: '_blank',
    title: 'Planes de Estudio',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/OfertaEducativa/PlanesdeEstudios.aspx?c=planesestudio',
    color: "#ff8500"
  },
  {
    desc: null,
    icon: 'card_travel',
    target: '_blank',
    title: 'Licitaciones',
    url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/Licitaciones.aspx',
    color: "#ff8500"
  },
]