/* eslint-disable no-unsafe-finally */
import axios from "axios"
const API_URL = "https://syrahapi.sanjuan.gob.ar/data"

export const obtenerForm = async () => {
  let data = {};
  const requestBody = {
    "site": "edu.formulario.educacion_portal"
  }

  try {
    const result = await axios.post(API_URL, requestBody);
    if (result.data.error) throw new Error(result.data.error);
    const isSuccess = result.data.items[0].success;
    if (isSuccess) {
      data = result.data.items;
    }
    else throw new Error("Ocurrio un error en la petición");
  }
  catch (err) {
    data = {
      items: [],
      error: err
    }
  }
  finally {
    console.log("data: ", data);
    return data;
  }
}