<template>
    <section>
        <div class="container">
            <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div class="row row-cols-2 row-cols-md-6 g-4 text-center" v-if="!items" >
                <div class="col" v-for="n in 6" :key="n" >
                    <div class="card">
                        <span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white loading-card-second">
                        </span>
                        <div class="card-body">
                            <h3 class="my-0 h6">
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-cols-2 g-4 text-center" :class="`row-cols-md-${this.columnas}`">
                <div class="col" v-for="(el , i) in items" :key="i">
                    <div class="card">
                        <span :style="{ backgroundColor: data[i].color }" class="card-img-top p-3 text-white">
                            <svg v-if="el.icon.includes('ptn')" class="ptn-i-2x" v-bind:class="[iconosGrandes ? 'ptn-i-3x' : '']">
                                    <use v-bind="{'xlink:href' : '#' + el.icon }"></use>
                            </svg>
                            <span v-if="!el.icon.includes('ptn')" class="material-symbols-outlined text-white ptn-i-2x" v-bind:class="[iconosGrandes ? 'ptn-i-3x' : '']">{{ el.icon }}</span>
                        </span>
                        <div class="card-body">
                            <h3 class="my-0 h6">
                                <a v-bind:href="el.url" v-bind:target="el.target ? el.target : '_self' " class="stretched-link">{{ el.title }}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
const axios = require("axios");
export default {
    name: "BoxIconGridComponent",
    props: {
        titulo: String,
        subtitulo : String,
        filtros : Object,
        data : Object,
        iconosGrandes : {
            type : Boolean,
            default: false
        },
        columnas: {
            type: Number,
            default: 6,
        },
    },
    data() {
        return {
            hash: "BIGC",
            items: null,
            title : null,
            desc : null
        };
    },
    mounted() {
        if(this.data == null){
            let params = {};
            if(this.filtros != null && this.filtros != ""){
                params = this.filtros;
            }
            params.tipo = this.hash;
            axios.post(process.env.VUE_APP_API_URL,params)
            .then(response => {
                this.items = response.data ? response.data.items : null
                this.title = response.data ? response.data.title : null
                this.desc = response.data ? response.data.desc : null
                localStorage.setItem(this.hash, JSON.stringify(response));
            })
            .catch (error => {
                if (!error.response) {
                    console.log("Fuera de Linea");
                    if(localStorage.getItem(this.hash)){
                        console.log("Cargando elementos de cache para " + this.hash);
                        var resp = JSON.parse(localStorage.getItem(this.hash));
                        this.items = resp.data ? resp.data.items : null                    
                        this.title = resp.data ? resp.data.title : null
                        this.desc = resp.data ? resp.data.desc : null
                    }
                }
            })
        }else{
            this.items = this.data;
        }
    }
};
</script>