<template>
  <section class="mt-4">
    <iframe :src="url" width="100%" frameborder="0" scrolling="" style="border: 0; height: 100vh;"></iframe>
  </section>
</template>

<script>

export default {
  name: "IframeViewComponent",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
      url: String
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  created() {
    const frameUid = "frame-";
    console.log(this.uid)
    const startIndex = this.uid.indexOf(frameUid);
    
    if (startIndex !== -1) {
      this.url = this.uid.substring(startIndex + frameUid.length);
    }
  },
};
</script>