export const accesos = [
    {
      desc: null,
      icon: 'card_travel',
      target: '_blank',
      title: 'ACTIVIDADES JUNTA',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/ActividadesJunta.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'list_alt',
      target: '_blank',
      title: 'NOVEDADES',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/Novedades.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'rate_review',
      target: '_blank',
      title: 'REGISTRO DE TITULOS',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/RegistrodeTítulos.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'apartment',
      target: '_blank',
      title: 'UNIDADES DE GESTIÓN',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/UnidaddeGestión.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'groups',
      target: '_blank',
      title: 'DESIGNACIONES DE DOCENTE',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/DesignaciónDocente.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'groups',
      target: '_blank',
      title: 'BAJAS Y LICENCIAS',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/TrámitesBajasyLicencias.aspx',
      color: "#ffae55"
    },
    {
      desc: null,
      icon: 'inventory_2',
      target: '_blank',
      title: 'DESBLOQUEO DE NOTEBOOKS',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/DesbloqueoNetbooks.aspx',
      color: "#ffae55"
    },
    
    {
      desc: null,
      icon: 'book',
      target: '_blank',
      title: 'CONTRATADOS',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/Contratados.aspx',
      color: "#ffae55"
    },
    /*
    {
      desc: null,
      icon: 'card_travel',
      target: '_blank',
      title: 'LICITACIONES',
      url: '?uid=frame-https://educacion.sanjuan.edu.ar/mesj/Prensa/Licitaciones.aspx',
      color: "#ffae55"
    }*/
  ]